<template>
  <div id="subpage-content">
    <CompanyDrawer />
    <div id="rcol">
      <v-card id="rcol-incard">
        <h1 id="naming">{{ $t("message.FIO3") }}</h1>
        <div id="about-text-work">
          <img
            width="220"
            height="250"
            id="workpic"
            :src="getImageUrlbyName('Wr1')"
          />
          <div id="pad">
            <v-icon small>mdi-email</v-icon>
            <a id = "pad-left" href="mailto:suhorukov@kg.ru">suhorukov@kg.ru</a>          
          </div>
          <div id="pad">
            <v-icon small>mdi-tag</v-icon>
            <span id = "pad-left">{{ $t("message.JobTitle3") }}</span>          
          </div>         
          <a id="backto" @click="$router.push('/company/workers')">
            <v-icon large>mdi-arrow-left-bold-circle</v-icon>
            <a id = "pad-left">{{ $t("message.WorkList") }}</a>          
          </a>
        </div>
      </v-card>
    </div>
  </div>
</template>

<style>
#history-card {
  display: flex;
}
#history-card-text {
  padding: 15px;
  background-color: rgb(224, 224, 224);
  width: 85%;
}
#about-text-work {
  display: flex;
  flex-direction: column;
}
</style>

<script>
import CompanyDrawer from "../../../../views/Navigations/Company/CompanyDrawer";
export default {
  components: {
    CompanyDrawer,
  },
};
</script>